<template>
  <div>
    <empty-list v-if="isUserEmpty" />
    <template v-else>
      <b-card
        class="mb-2"
        body-class="px-3 py-2"
        v-for="(user, index) in users"
        :key="index"
      >
        <router-link :to="`/users/detail/${user.id}`">
          <div class="align-items-center">
            <section class="d-flex align-items-center gap-2">
              <avatar
                :photo="user.photo"
                :is_online="user.is_online"
                :name="user.full_name"
              />
              <div>
                <strong class="d-block w-100">
                  {{ user.full_name }}
                </strong>
                <div>
                  <small>{{ user.email }}</small>
                </div>
              </div>
            </section>
          </div>
        </router-link>
      </b-card>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      isUserEmpty: false,
      users: [],
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    ...mapActions(["get_all_user"]),
  },

  mounted() {
    this.get_all_user({
      per_page: 5,
    }).then(({ data }) => {
      this.users = data.results;
      this.isUserEmpty = this.users?.length === 0;
    });
  },
};
</script>
