<template>
    <b-modal static hide-footer title="Filtrar Serviços" body-class="min-h-40" size="lg" centered id="filter-service">
        <filter-service />
    </b-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import FilterService from "./form.vue";

import Cards from "./cards";

export default {
    components: {
        FilterService
    },

    data() {
        return {
            dashboard: {},
        };
    },

    computed: {
        ...mapGetters(["user"]),
    },
    methods: {

    },
    mounted() {

    },
    beforeDestroy() {

    },
};
</script>