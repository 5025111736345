<template>
  <div>
    <b-form @submit.prevent="handleSubmit" class="w-100">
      <div class="row">
        <section class="col-md-12">
          <!-- Sequencial -->
          <b-form-group label="Sequencial">
            <b-form-input v-mask="'####-######'" v-model="form.sequence"
              :placeholder="dynamicPlaceholderSequence"></b-form-input>
          </b-form-group>

          <!-- Status do Serviço -->
          <b-form-group label="Status do Serviço">
            <multiselect track-by="id" label="status" required searchable close-on-select :show-labels="false"
              :internal-search="false" placeholder="Selecione um Status" v-model="form.status"
              :options="statusOptions" />
          </b-form-group>

          <!-- Data -->
          <b-form-group label="Data">
            <b-form-datepicker v-model="form.search_date" placeholder="00/00/0000" :date-format-options="dateFormat"
              locale="pt-BR"></b-form-datepicker>
          </b-form-group>

          <!-- Escopo -->
          <b-form-group label="Escopo">
            <b-form-input v-model="form.scope" placeholder="Nome do Serviço"></b-form-input>
          </b-form-group>

          <!-- Tomador -->
          <b-form-group label="Tomador">
            <b-form-input v-model="form.homologation_borrower" placeholder="Empresa Tomadora"></b-form-input>
          </b-form-group>

          <!-- Prestador -->
          <b-form-group label="Prestador">
            <b-form-input v-model="form.providers" placeholder="Empresa Prestadora"></b-form-input>
          </b-form-group>
        </section>
      </div>

      <div class="action d-flex justify-content-end w-100 gap-3">
        <b-button @click="clearForm" variant="light">Limpar</b-button>
        <b-button type="submit" variant="primary" :disabled="isLoading" class="pull-right text-right">
          <b-spinner v-if="isLoading" small class="mr-2" />
          <span> Pesquisar </span>
        </b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import { BForm, BFormInput, BFormGroup, BButton, BFormDatepicker } from "bootstrap-vue";
import { mapActions } from "vuex";

export default {
  components: {
    BForm,
    BFormInput,
    BFormGroup,
    BButton,
    BFormDatepicker,
    Multiselect,
  },
  data() {
    return {
      dateFormat: {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      },
      isLoading: false,
      form: {
        sequence: null,
        status: null,
        search_date: null,
        scope: null,
        homologation_borrower: null,
        providers: null,
      },
      statusOptions: [
        { id: 0, status: "Não Iniciado" },
        { id: 1, status: "Em Progresso" },
        { id: 2, status: "Finalizado" }
      ]
    };
  },
  computed: {
    dynamicPlaceholderSequence() {
      const currentYear = new Date().getFullYear();
      return `${currentYear}-000001`;
    }
  },
  methods: {
    ...mapActions(["get_all_service_scope"]),
    handleSubmit() {
      this.isLoading = true;

      // Prepare query parameters
      const params = {
        ...this.form,
        status: this.form.status ? this.form.status.id : null, // Send only the ID of status
      };

      // Send the GET request with query parameters
      this.get_all_service_scope(params)
        .catch(error => {
          console.error("Error submitting form:", error);
        });
      this.isLoading = false;
      this.$bvModal.hide("filter-service");
    },
    setDate() {
      return this.moment(new Date()).format("YYYY-MM-DD");
    },
    clearForm() {
      this.form = {
        sequence: null,
        status: null,
        search_date: null,
        scope: null,
        homologation_borrower: null,
        providers: null,
      };
    }
  },
};
</script>

<style scoped>
.multiselect {
  width: 100%;
}
</style>
