<template>
  <div class="d-flex gap-3">
    <div class="col-md-8 p-0">
      <cards-list grid="3" gap="15" class="w-100 H-100">
        <b-card v-for="(card, index) in dashboard.cards" :key="index">
          <div class="media">
            <div class="media-body">
              <h3 class="mb-2">
                {{ handleFormatValue(card.value, card.type) }}
              </h3>
              <p class="mb-2">{{ card.description }}</p>
              <div class="mb-0">
                <span class="text-muted">{{ card.alt }}</span>
              </div>
            </div>
            <div class="d-inline-block ml-3">
              <div class="stat">
                <b-icon :icon="card.icon" class="h3" :variant="card.variant" />
              </div>
            </div>
          </div>
        </b-card>
      </cards-list>
    </div>
    <b-card v-if="dashboard && dashboard.enterprise" class="col-md-4 p-0">
      <span class="d-block">Você está representando a empresa:</span>
      <b-media class="my-2">
        <template #aside>
          <b-img
            v-if="dashboard.enterprise.logo.url"
            :src="dashboard.enterprise.logo.url"
            width="50"
            :alt="dashboard.enterprise.logo.name"
          />
          <img v-else src="@/assets/logo-full.png" width="50" />
        </template>

        <h6 class="mt-0">{{ dashboard.enterprise.social_reason }}</h6>
        <p class="mb-0">
          {{ dashboard.enterprise.cnpj | VMask("##.###.###/####-##") }}
        </p>
      </b-media>
      <small>
        Cadastrada desde: {{ dashboard.enterprise.created_at | date }}
      </small>
    </b-card>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {};
  },
  props: {
    dashboard: Object,
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    handleFormatValue(value, type) {
      if (value === 0 && type !== "money") return value;

      const val = this.$options.filters[type](value);
      return val;
    },
  },
};
</script>
