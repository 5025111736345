<template>
  <div>
    <empty-list v-if="services.length === 0" text="Sem serviços para exibir" />
    <template v-else>
      <b-card class="mb-2" body-class="px-3 py-2" v-for="(service, index) in services" :key="index">
        <cards-list grid="6" responsive="2" gap="20" class="align-items-center">
          <div>
            <small>Sequencial</small>
            <div class="d-flex align-items-center gap-2">
              <span>{{ service.sequence }}</span>
            </div>
          </div>
          <div>
            <small>Tomador</small>
            <div class="d-flex align-items-center gap-2">
              <span>{{ service.company_name }}</span>
            </div>
          </div>
          <div>
            <small>Escopo</small>
            <div class="d-flex align-items-center gap-2">
              <span>{{ service.scope }}</span>
            </div>
          </div>
          <div>
            <small>Status</small>
            <span :class="`text-${getStatus(service.status).variant
              } d-flex align-items-center`
              ">
              {{ getStatus(service.status).label }}
            </span>
          </div>

          <div>
            <small>Progresso</small>
            <b-progress style="height: 10px" :max="100" class="w-100 mt-2">
              <b-progress-bar :value="service.percentage || 0" variant="primary" />
            </b-progress>
          </div>
          <div>
            <small>Período</small>
            <div class="d-flex align-items-center gap-2">
              <span>{{ service.date_init | date }}</span>
              <small>à</small>
              <span>{{ service.date_end | date }}</span>
            </div>
          </div>

          <b-button :to="`/homologation/${service.homologation}/service/${service.id}/${user.role}/validation`
            ">
            Visuzalizar
          </b-button>
        </cards-list>
      </b-card>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      services: [],
    };
  },
  computed: {
    ...mapGetters(["user", "service_scopes"]),
  },
  methods: {
    ...mapActions(["get_all_service_scope"]),
    getStatus(status = 0) {
      let icon;
      let variant;
      let label;
      switch (status) {
        case 0:
          variant = "info";
          label = "Não inicializado";
          break;
        case 1:
          variant = "primary";
          label = "Em progresso";
          break;
        case 2:
          variant = "success";
          label = "Finalizado";
          break;
      }
      return {
        icon,
        variant,
        label,
      };
    },
  },

  mounted() {
    this.get_all_service_scope({
      per_page: 10,
    })
  },

  watch: {
    service_scopes(value) {
      this.services = value;
    }
  }
};
</script>
